<script setup>
import { useForm } from "vee-validate";
import * as Yup from "yup";
import { RecaptchaV2, useRecaptcha } from "vue3-recaptcha-v2";

// ==================== VALIDATION ====================

const subscribeSchema = Yup.object({
  firstName: Yup.string().required().label("First name"),
  lastName: Yup.string().required().label("Last Name"),
  email: Yup.string().required().email().label("Email"),
});

const { $api } = useNuxtApp();
const notificationStore = useNotificationStore();
const config = useRuntimeConfig();
const { handleSubmit, errors } = useForm({
  validationSchema: subscribeSchema,
});
const { handleReset } = useRecaptcha();

// ==================== CONSTANTS ====================

const recaptchaToken = ref("");
const captchaClass = reactive({
  hidden: true,
});

// ==================== FUNCTIONS ====================

const handleWidgetId = (widgetId) => {
  handleReset(widgetId);
  recaptchaToken.value = "";
};
const handleLoadCallback = (response) => {
  recaptchaToken.value = response;
};

function onInvalidSubmit({ errors }) {
  notificationStore.ADD({
    type: "error",
    title: "Invalid Data",
    message: `${Object.keys(errors)
      .map((key) => errors[key])
      .join(", ")}`,
  });
}

const onSubmit = handleSubmit(async (values, { resetForm }) => {
  if (!recaptchaToken.value) {
    notificationStore.ADD({
      type: "error",
      title: "reCAPTCHA is required",
    });
    captchaClass.hidden = false;
    return;
  }

  try {
    await verifyReCaptcha(recaptchaToken.value);
  } catch (e) {
    notificationStore.ADD({
      type: "error",
      title: "Failed verify reCAPTCHA",
      message: "Please try a bit later.",
    });
    return;
  }

  const { firstName, lastName, email } = values;

  const { data, error } = await $api.post(`add-subscribe`, {
    method: "POST",
    body: { firstName, lastName, email },
  });

  handleWidgetId();
  captchaClass.hidden = true;

  if (error.value || data.value.error) {
    resetForm();
    return;
  }

  notificationStore.ADD({
    type: "success",
    title: "Successfully subscribed",
  });
  resetForm();
}, onInvalidSubmit);

async function verifyReCaptcha(token) {
  const { data } = await $api.get(`/api/verify-recaptcha`, {
    params: { token },
  });

  return data.success;
}
</script>

<template>
  <form class="ml-0 flex w-full flex-col gap-4 lg:ml-12" @submit="onSubmit">
    <fieldset>
      <legend class="mb-4 text-xl font-semibold text-[#3B5162] dark:text-white">Get the latest CEEK updates</legend>
      <div
        class="grid h-fit cursor-text grid-cols-1 gap-3 rounded-full bg-transparent lg:grid-cols-4 lg:gap-0 lg:bg-white xl:grid-cols-7"
      >
        <label
          class="col-span-1 w-full rounded-full bg-white p-3 text-base lg:rounded-l-full lg:rounded-r-none xl:col-span-2 xl:px-5 xl:text-lg 2xl:text-xl"
          for="firstName"
        >
          <AtomInputField
            id="firstName"
            class="w-full outline-none placeholder:font-semibold"
            type="text"
            name="firstName"
            placeholder="First name"
            autocomplete="given-name"
          />
        </label>
        <label
          class="col-span-1 w-full rounded-full border-0 bg-white p-3 text-base dark:border-[#1D1D1D] lg:rounded-none lg:border-l-4 xl:col-span-2 xl:px-5 xl:text-lg 2xl:text-xl"
          for="lastName"
        >
          <AtomInputField
            id="lastName"
            class="w-full outline-none placeholder:font-semibold"
            type="text"
            name="lastName"
            placeholder="Last name"
            autocomplete="family-name"
          />
        </label>
        <label
          class="col-span-1 w-full rounded-full border-0 bg-white p-3 text-base dark:border-[#1D1D1D] lg:rounded-none lg:border-l-4 xl:col-span-2 xl:px-5 xl:text-lg 2xl:text-xl"
          for="email"
        >
          <AtomInputField
            id="email"
            class="w-full outline-none placeholder:font-semibold"
            type="email"
            name="email"
            placeholder="Email"
            autocomplete="email"
          />
        </label>
        <AtomButton
          class="col-span-1 mx-auto w-full scale-100 whitespace-nowrap rounded-full bg-[#0D00FF] px-5 py-3 text-base font-semibold text-white transition-all hover:shadow-around hover:shadow-[#0D00FF] active:scale-90 lg:scale-110 lg:py-0 xl:text-lg 2xl:text-xl"
          type="submit"
        >
          I'm in
        </AtomButton>
      </div>
      <span v-if="Object.keys(errors).length" class="mt-2 block text-sm text-red-600 sm:text-base">{{
        errors[Object.keys(errors)[0]]
      }}</span>
      <RecaptchaV2
        :class="captchaClass"
        class="mt-5 flex justify-center"
        @widget-id="handleWidgetId"
        @load-callback="handleLoadCallback"
      />
    </fieldset>
  </form>
</template>
